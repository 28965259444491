import React from 'react';
import { withStyles,} from '@material-ui/core';
import {Button} from  '@material-ui/core';

const CustomButton = withStyles(theme => ({
  root: {
    // height: 30,
    color: theme.palette.common.white,
    backgroundColor: '#002250',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#002250'
    },
    borderRadius: '4px'
  }
}))(Button);


export default CustomButton;
