import React, { createContext, useReducer } from 'react';
import gql from 'graphql-tag';
import client from '../utils/GQLAdmissionClient';
import axios from 'axios';

const initialCreateState = {
  isCreated: false,
  isInitialised: false,
  user: null,
  users: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isCreated, user } = action.payload;

      return {
        ...state,
        isCreated,
        isInitialised: true,
        user
      };
    }

    case 'UPDATE_INSTITUTION': {
      const { user, updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull,
        user
      };
    }
    case 'UPDATE_INSTITUTION_ERR': {
      const { err, updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull: false,
        err
      };
    }
    case 'ADD_IMPORT': {
      const { user1, updatesuccessfull1 } = action.payload;

      return {
        ...state,
        updatesuccessfull1,
        user1
      };
    }
    case 'ADD_IMPORT_ERROR': {
      const { err1, updatesuccessfull1 } = action.payload;

      return {
        ...state,
        updatesuccessfull1: false,
        err1
      };
    }
    default: {
      return { ...state };
    }
  }
};

const FeeSetupContext = createContext({
  ...initialCreateState,
  addlop: () => Promise.resolve(),
  addSessionDetails: () => Promise.resolve(),
  uploadAttendance: () => Promise.resolve(),
  adddeductions: () => Promise.resolve(),
  addallowances: () => Promise.resolve()
});

export const FeeSetupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCreateState);

  const addlop = async data => {
    console.log('hit');

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_lop',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_lop',
          data
        );
      }
      console.log(response);
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      console.log(err.message);
    }
  };
  const uploadAttendance = async data => {
    console.log('hit');
    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://3yj8easi7j.execute-api.ap-south-1.amazonaws.com/test/upload_emp_attendance',
          data
        );
      } else {
        response = await axios.post(
          'https://3yj8easi7j.execute-api.ap-south-1.amazonaws.com/prod/upload_emp_attendance',
          data
        );
      }
      console.log(response);
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response,
            updatesuccessfull: false
          }
        });
      }
      console.log(err.message);
    }
  };

  const addSessionDetails = async data => {
    console.log('hit');

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_contract_emp_session',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_contract_emp_session',
          data
        );
      }
      console.log(response);
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      console.log(err.message);
    }
  };

  const adddeductions = async data => {
    console.log('hit');

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_deduction',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_deduction',
          data
        );
      }
      console.log(response);
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      console.log(err.message);
    }
  };

  const addallowances = async data => {
    console.log('hit');

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_allowance',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_allowance',
          data
        );
      }
      console.log(response);
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      console.log(err.message);
    }
  };

  return (
    <FeeSetupContext.Provider
      value={{
        ...state,
        addlop,
        adddeductions,
        addSessionDetails,
        uploadAttendance,
        addallowances
      }}
    >
      {children}
    </FeeSetupContext.Provider>
  );
};

export default FeeSetupContext;
