/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import Logo from 'src/components/Logo';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

import {
  Box,
  Divider,
  Drawer,
  Hidden,
  AppBar,
  Toolbar,
  IconButton,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  withStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import Account from './Account';
import Stream from './Stream';
import Year from './Year';

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import SplashScreen from 'src/components/SplashScreen';
import Search from './Search';
import {
  Edit as EditIcon,
  BarChart as BarChartIcon,
  PieChart as PieChartIcon
} from 'react-feather';
import NavItem from './NavItem';
import Label from 'src/components/Label';
import Notifications from './Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import Rules from 'src/Rules.json'
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import {
  useDashboardContext
} from 'src/contexts/DashboardContext';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import Settings from './Settings';

const ColorAppBar = withStyles({
  root: {
    backgroundColor: '#4F4E7D'
  }
})(AppBar);

const Dashboard = {
  items: [
    {
      title: 'Dashboard',
      href: '/app/payroll/home',
      icon: 'material-symbols:dashboard-outline'
    }
  ]
};

const Owner = {
  items: [
    // {
    //   title: 'Employees',
    //   href: '/app/admin/Faculty',
    //   icon: 'heroicons:user-group'
    // },
    {
      title: 'Employees',
      href: '/app/admin/Faculty/employee_view',
      icon: 'heroicons:user-group'
    },
    {
      title: 'Payout',
      href: '/app/payroll/schedule',
      icon: 'tdesign:money'
    },
    // {
    //   title: 'Employee Tracker',
    //   href: '/app/admin/Employees',
    //   icon: 'la:id-card-solid',
    //   items: [
    //     {
    //       title: 'Attendance',
    //       href: '/app/admin/Employees/attendance/attendence_view'
    //       // icon: 'material-symbols-light:home-outline'
    //     },
    //     {
    //       title: 'Leaves Applications',
    //       href: '/app/payroll/leaveApplications/emp_leave'
    //       // icon: 'material-symbols-light:home-outline'

    //       // icon: AssignmentTurnedInOutlinedIcon
    //     },
    //     {
    //       title: 'Movement Register',
    //       href: '/app/payroll/Employee/emp_movement'
    //       // icon: 'material-symbols-light:home-outline'

    //       // icon: AssignmentTurnedInOutlinedIcon
    //     }
    //   ]
    // },
    {
      title: 'HR Tracker',
      href: '/app/payroll',
      icon: 'la:id-card-solid',
      items: [
        {
          title: 'Attendance',
          href: '/app/payroll/attendancenew'
          // icon: 'material-symbols-light:home-outline'

          // icon: AssignmentTurnedInOutlinedIcon
        },
        {
          title: 'Timesheet',
          href: '/app/payroll/attendance'
          // icon: 'material-symbols-light:home-outline'

          // icon: AssignmentTurnedInOutlinedIcon
        },

        {
          title: 'Leave Applications',
          href: '/app/admin/leaveApplications/hr_leave'
          // icon: 'material-symbols-light:home-outline'

          // icon: TransformOutlinedIcon
        },
        {
          title: 'Movement Register',
          href: '/app/admin/movement_register/hr_movement'
          // icon: 'material-symbols-light:home-outline'

          // icon: TransferWithinAStationIcon
        }
      ]
    },
    {
      title: 'Approvals',
      href: '/app/admin/Employees/approvals',
      icon: 'ic:baseline-transform'
    },
    {
      title: 'Reports',
      href: '/app/payroll/reports',
      icon: 'mdi:report-box-multiple-outline'
    },
    // {
    //   title: 'User Management',
    //   href: '/app/admin/user_management',
    //   icon: 'material-symbols:swap-vertical-circle-outline'
    // },
    // {
    //   title: 'Settings',
    //   href: '/app/payroll/setup',
    //   icon: 'ic:outline-settings',
    //   items: [
    //     {
    //       title: 'Face Id Setup',
    //       href: '/app/payroll/setup/attendance'
    //     },
    //     {
    //       title: 'Dept Admin Setup',
    //       href: '/app/payroll/setup/deptAdminSetup'
    //     },
    //     {
    //       title: 'Manage Attendance',
    //       href: '/app/payroll/ManageAttendance'
    //     }
    //   ]
    // }
  ]
};

const HOD = {
  items: [
    {
      title: 'Employees',
      href: '/app/admin/Faculty/employee_view',
      icon: 'heroicons:user-group'
    },

  
    {
      title: 'HR Tracker',
      href: '/app/payroll',
      icon: 'la:id-card-solid',
      items: [
        {
          title: 'Attendance',
          href: '/app/payroll/attendancenew'
          // icon: 'material-symbols-light:home-outline'

          // icon: AssignmentTurnedInOutlinedIcon
        },
        {
          title: 'Timesheet',
          href: '/app/payroll/attendance'
          // icon: 'material-symbols-light:home-outline'

          // icon: AssignmentTurnedInOutlinedIcon
        },

        {
          title: 'Leave Applications',
          href: '/app/admin/leaveApplications/hr_leave'
          // icon: 'material-symbols-light:home-outline'

          // icon: TransformOutlinedIcon
        },
        {
          title: 'Movement Register',
          href: '/app/admin/movement_register/hr_movement'
          // icon: 'material-symbols-light:home-outline'

          // icon: TransferWithinAStationIcon
        }
      ]
    },
    {
      title: 'Approvals',
      href: '/app/admin/Employees/approvals',
      icon: 'ic:baseline-transform'
    },
    {
      title: 'Incharge HOD',
      href: '/app/payroll/inchargeHOD',
      icon: 'mingcute:transfer-3-line'
    },
    {
      title: 'Reports',
      href: '/app/payroll/reports',
      icon: 'mdi:report-box-multiple-outline'
    },
    // {
    //   title: 'Organization',
    //   href: '/app/payroll/Organization',
    //   icon: "material-symbols-light:home-outline"

    // },
   
  ]
};


const Attendance_entry = {
  items: [
    {
      title: 'Attendance Entry',
      href: '/app/payroll/attendanceEntry',
      icon: 'material-symbols:assignment-turned-in-outline'
    }
  ]
};

const load = state => {
  if (state) return <SplashScreen />;
};
function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor:'#ffffff'
  },
  root1: {
    display: 'flex',
    paddingLeft: 256,
    transitionDuration: 500,
    backgroundColor:'#ffffff'

  },
  desktopDrawer: {
    width: 256,
    height: '100%',
    backgroundColor:Rules.nav_top_bar.navbar_background_color,
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const sections = [
  {
    items: [
      {
        icon: PieChartIcon,
        href: <Search />
      },
      {
        icon: BarChartIcon,
        href: <Search />
      }
    ]
  }
];

function renderRightNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceRightChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceRightChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderRightNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useRightStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 70,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const useLogoStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 70,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 70
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const TopBar = ({
  className,

  onMobileNavOpen,
  onMobileNavClose,
  ...rest
}) => {
  const classes = useStyles();
  const rightClasses = useRightStyles();
  const logoClasses = useLogoStyles();
  const location = useLocation();
  const { user } = useAuth();
  let users = '';

  const [image, setImage] = useState(null);
  const arrayBufferToBase64 = buffer => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(b => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };

  let folderName = user['custom:institution_id'];
  var bucketName = 'erpinstitution';
  let img;
  let logo;
  const s3 = new AWS.S3();

  async function get_logo(image_path) {
    let img = await s3
      .getObject({
        Bucket: bucketName,
        Key: image_path
      })
      .promise()
      .then(res => {
        if (res) {
          console.log('logo', res);
          return res;
        } else {
          return null;
        }
      })
      .catch(err => {
        console.log(err);
      });
    return img;
  }

  const getCustomers = useCallback(async () => {
    try {
      const get_image = gql`
        query get_setup {
          get_setup {
            _id
            institution_logo
          }
        }
      `;
      var image_path =
        encodeURIComponent(folderName) +
        '/' +
        encodeURIComponent('logo') +
        '/' +
        user['custom:institution_id'];

      img = await get_logo(image_path);
      if (img === undefined || img === null) {
        logo = null;
      } else {
        var base64Flag = `data:${img.contentType};base64,`;
        var imgData = arrayBufferToBase64(img.Body);
        logo = base64Flag + imgData;
        setImage(logo);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);
  const { open, setOpen } = useDashboardContext();

  const handleDrawer = () => {
    if (open) {
      setOpen(false);
      window.localStorage.setItem('drawer', false);
    } else {
      setOpen(true);
      window.localStorage.setItem('drawer', true);
    }
  };

  window.addEventListener('storage', function(e) {
    console.log('storage fired');
    // this.fetchData();
  });
  console.log(window.dispatchEvent(new Event('storage')));

  window.addEventListener('storage', console.log);

  window.localStorage.setItem('test', '123');
  window.dispatchEvent(new Event('storage'));
  const theme = useTheme();
  const textSecondaryColor = theme.palette.text.secondary;
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
      <Box pt={2.5} pb={1.4} px={1.5}>
          <Box mt={-1} ml={1.2} display="flex">
            {open === true && (
             <>
              <IconButton
                color="inherit"
                size="small"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
                className={clsx(classes.menuButton, open)}
              >
                <MenuIcon fontSize="large" style={{color:'#ffffff'}} />
              </IconButton>
               <Box mr={2} />
               {open && <Box mt={1}>
                  <Typography
                    variant="h5"
                    style={{ color: '#ffffff', fontSize: '20px' }}
                  >
                    Payroll
                  </Typography>
                </Box>

                }
                
</>
            )}
            {open === false && (
              <>
              <Box mr={1}/>
              <IconButton
                color="inherit"
                size="small"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
                className={clsx(classes.menuButton1, open)}
              >
                <MenuOpenIcon fontSize="large" style={{color:'black'}}/>
              </IconButton></>
            )}
           
          </Box>
        </Box>{' '}
        <Box mt={1} />
        {user.user_group !== 'owner' &&
          user.user_group !== 'admin' &&
          user.user_group !== 'attendance_entry' && <Divider />}

        <Box p={2} pb={0}>
          {(user.user_group === 'owner' ||
            user.user_group === 'admin' ||
            user.user_group === 'hod'||
            user.user_group === 'member') && (
            <>
              {/* <Box mb={3} mt={0} ml={1} display="flex">
                <Box mt={0} ml={0.3}>
                  <Typography variant="body1" color="textSecondary">
                    Admin Actions
                  </Typography>
                </Box>
              </Box> */}
              <Box mt={-3.5} />
            </>
          )}
          {(user.user_group === 'owner' ||
            user.user_group === 'admin' ||
            user.user_group === 'hod'||
            user.user_group === 'member'
          
          ) && (
            <>
              <List key={Dashboard.subheader}>
                {renderNavItems({
                  items: Dashboard.items,
                  pathname: location.pathname
                })}
              </List>{' '}
              <Box mt={-1} />
            </>
          )}
          {user.user_group === 'attendance_entry' && (
            <List
              key={Attendance_entry.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {Attendance_entry.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: Attendance_entry.items,
                pathname: location.pathname
              })}
            </List>
          )}
          {(user.user_group === 'owner' || user.user_group === 'admin') && (
            <>
              <List
                key={Owner.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {Owner.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: Owner.items,
                  pathname: location.pathname
                })}
              </List>
            </>
          )}
          {(user.user_group === 'hod' ||
            user.user_group === 'member') && (
            <List
              key={HOD.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {HOD.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: HOD.items,
                pathname: location.pathname
              })}
            </List>
          )}
        </Box>
      </PerfectScrollbar>

      <Box flexGrow={1} />
      <Box pt={2} />
      <Divider />
      {/* <Box p={1} pl={5} borderRadius="borderRadius"
        //  bgcolor="background.dark"
         >
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/app/admin/help_section"
              className={classes.action}
            >
              Resolve My Issue
            </Link>
          </Box> */}

      <Divider />
    </Box>
  );

  const Logocontent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Divider />

        <Box p={2}></Box>
        <Divider />
        <Box p={2} flexDirection="column" justifyContent="flex-end">
          <Box flexGrow={1} />
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              // component={RouterLink}
              // to="/docs"
              className={classes.action}
            >
              Check our docs
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
  const theme1 = useTheme();

  const mobileDevice = useMediaQuery(theme1.breakpoints.down('xs'));

  return (
    <div>
      {mobileDevice ? (
        <>
          {/* <CssBaseline />

          <ColorAppBar className={om ? classes.root1 : classes.root} {...rest}>
            <Toolbar className={classes.toolbar}>            
              <Stream />
              <Box ml={2} flexGrow={1} />
              <Year />
              <Term />
              <Box ml={2}>
                <Account Image={image} />
              </Box>
            </Toolbar>
          </ColorAppBar>
          <Drawer
            classes={{ paper: classes.mobileDrawer }}
            variant="temporary"
            anchor="left"
            open={om}
            onClose={handleDrawer1}
          >
            {content}
          </Drawer> */}
        </>
      ) : (
        <>
          {' '}
          <CssBaseline />
          <ColorAppBar className={open ? classes.root1 : classes.root} {...rest}>
            <Toolbar className={classes.toolbar}>
            {open === false && (
            <IconButton
            style={{color:'#000000'}}
            aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              className={clsx(classes.menuButton, open)}
            >
              <MenuOpenRoundedIcon fontSize="large" />
            </IconButton>
          )}

              <Hidden mdDown>
                {/* <Avatar
          src= {"/static/company.png"}
          ></Avatar> */}
                <Box />
                {/* <Avatar
          src= {image}
          ></Avatar> */}
              </Hidden>
              <Stream image={image} />

              <Box ml={2} flexGrow={1} />
              <Year />
              {/* <Search /> */}
              {/* <Contacts /> */}
              <Box ml={1}>
                <Notifications />
              </Box>
               <Settings />
              <Box ml={2}>
                <Account />
              </Box>
            </Toolbar>
          </ColorAppBar>
          <Drawer
            classes={{ paper: classes.desktopDrawer }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            {content}
          </Drawer>
        </>
      )}
      {/* {open === false && (
        <Hidden mdDown>
       <Drawer
       classes={{ paper: logoClasses.desktopDrawer }}
       variant="persistent"
       anchor="left"
       open={true}
     >
       {Logocontent}
     </Drawer>
     </Hidden>
     )} */}
      {/* <Drawer
        classes={{ paper: rightClasses.desktopDrawer }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        {Rightcontent}
      </Drawer> */}
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
