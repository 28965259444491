import getInitials from 'src/utils/getInitials';
import getName from 'src/utils/getName';

import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Typography,SvgIcon,
  makeStyles,
  Dialog
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import ChangePassword from 'src/views/auth/LoginView/Change_Password';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LockResetIcon from '@mui/icons-material/LockReset';
import { Link as RouterLink } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Person2Icon from '@mui/icons-material/Person2';
import Rules from 'src/Rules.json'
const useStyles = makeStyles(theme => ({
  avatar: {
    height: 38,
    width: 38,    
    backgroundColor:Rules.nav_top_bar.navbar_background_color,
    marginRight: theme.spacing(1)
  },
  avatar1: {
    height: 41,
    width: 41,
    backgroundColor:Rules.nav_top_bar.navbar_background_color,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  },
  menuItem: {
    root:{
      backgroundColor: '#ffffff',
      // '&:hover': {
      //   backgroundColor: theme.palette.background.dark
      // },
    },
    color: theme.palette.background.default,
    padding: '12px 24px',
    boxShadow: theme.shadows[7],   
    borderRadius: '12px',
    marginBottom: 1,
    padding: 1.8,
    minWidth: 100,
    margin: '6px 0'
  },
  menuItem1: {
    root:{
      backgroundColor: '#ffffff',
      '&:hover': {
        backgroundColor: theme.palette.background.dark
      },
    },   
    borderRadius: '12px',
    marginBottom: 1,
    padding: 1.8,
    minWidth: 100,
    margin: '6px 0'
  },
  item: {
    margin: 5,
    paddingBottom: 8,
    paddingTop: 8,
    paddingRight: 2,
    paddingLeft: 3,
    borderRadius: '12px',
    backgroundColor: theme.palette.background.dark,
    '&:hover': {
      backgroundColor: theme.palette.background.dark
    }
  }
}));

const Account = ({ Image }) => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push('/login');
    } catch (err) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  const [resetopen, setResetOpen] = useState(false);

  const handleClickResetOpen = () => {
    setResetOpen(true);
    handleClose();
  };
  const handleResetClose = () => {
    setResetOpen(false);
  };
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          variant="roun"
          className={classes.avatar}
          src={Image}
        >
          {getInitials(
            `${user['custom:first_name']} ${user['custom:last_name']}`
          )}
        </Avatar>

        {/* <Hidden smDown>
          <Typography variant="h6" color="inherit" color="textPrimary">
            {getName(`${user['custom:first_name']}`)}{' '}
            {getName(`${user['custom:last_name']}`)}
          </Typography>
        </Hidden> */}
      </Box>
      <Menu
        onClose={handleClose}
        keepMounted
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
        PaperProps={{
          elevation: 5,
          sx: {
            backgroundColor:'#ffffff',
            minWidth: '300px',
            padding: '10px 18px 8px 18px',
            borderRadius: '12px',
            '& .MuiMenuItem-root': {
              borderRadius: '12px',
           
              marginBottom: 1,
              padding: 1.8,
              minWidth: 100
            },
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem className={classes.menuItem} >
          <Box display="flex" justifyContent='center'>
          <Avatar src={Image} className={classes.avatar1}></Avatar>
          <Box>
          <Typography variant="h6" color="textPrimary">
            {getName(`${user['custom:first_name']}`)}{' '}
            {getName(`${user['custom:last_name']}`)}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            {user['email']}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            {user['phone_number']}
          </Typography>
          </Box>
          </Box>
        </MenuItem>
        {/* <MenuItem className={classes.menuItem1}
        component={RouterLink}
        to={`/app/admin/profile1/personaldetails`}
        onClick={()=>handleClose()}
        >
          <Box display='flex'>
          <SvgIcon>
              <Person2Icon/>
            </SvgIcon>
            <Box mr={2}/>

            <Typography variant="h5">My Account</Typography>
          </Box>
        </MenuItem> */}
        {/* <MenuItem className={classes.menuItem1} onClick={handleClickResetOpen}>
          <Box display='flex'>
          <SvgIcon>
              <LockResetIcon/>
            </SvgIcon>
            <Box mr={2}/>
            <Typography variant="h5">Reset Password</Typography>
          </Box>
        </MenuItem> */}
        <MenuItem className={classes.menuItem1} onClick={handleLogout}>
          <Box display='flex'>
            <SvgIcon>
              <ExitToAppIcon/>
            </SvgIcon>
            <Box mr={2}/>
            <Typography variant="h5">Logout</Typography>
          </Box>
        </MenuItem>
      </Menu>
      {/* <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleClickResetOpen}>Reset Password</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu> */}
      <Dialog
        maxWidth="sm"
        fullWidth
        //onExit = {getStudentByEmail} // here wite the query function name so that data re-render after CRUD Operation
        onClose={handleResetClose}
        open={resetopen}
      >
        <ChangePassword open={handleResetClose} />
      </Dialog>
    </>
  );
};

export default Account;
