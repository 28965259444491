import React from 'react';
import { withStyles,} from '@material-ui/core';
import {Dialog} from  '@material-ui/core';

const CustomDialog = withStyles({
  paper: {
    //borderRadius: '16px'
  }
})(Dialog);


export default CustomDialog;