// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_appsync_graphqlEndpoint": "https://5tavsrqfyzdwbpm7lkupfuf6le.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-5uceqebhcbebhnqgo2hnvc25tu",
};


export default awsmobile;