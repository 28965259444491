import React, { createContext, useReducer } from 'react';
import axios from 'axios';

const initialCreateState = {
  isCreated: false,
  isInitialised: false,
  user: null,
  users: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'GET_SUCCESS': {
      const { user } = action.payload;

      return {
        ...state,
        user
      };
    }
    case 'GET_ERR': {
      const { err,  } = action.payload;

      return {
        ...state,
        err
      };
    }
    default: {
      return { ...state };
    }
  }
};

const ReportContext = createContext({
  ...initialCreateState,
  PaybillCategoryWise: () => Promise.resolve()
});

export const ReportProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCreateState);

  const PaybillCategoryWise = async data => {
    console.log('hit');

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://reportapidev.rovelabs.com/report/PaybillCategoryWise',
          data
        );
      } else {
        response = await axios.post(
          'https://reportapi.rovelabs.com/report/PaybillCategoryWise',
          data
        );
      }
      console.log(response);
      dispatch({
        type: 'GET_SUCCESS',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response) {
        dispatch({
          type: 'GET_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      console.log(err.message);
    }
  };

  return (
    <ReportContext.Provider
      value={{
        ...state,
        PaybillCategoryWise
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export default ReportContext