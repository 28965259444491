import React, {
  createContext,
  useEffect,
  useReducer,
  useCallback
} from 'react';
import SplashScreen from 'src/components/SplashScreen';
import Cookies from 'js-cookie';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import client from 'src/utils/GQLCompanyClient';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Settingclient from 'src/utils/GQLSettingsClient';

require('dotenv').config();

const initialAuthState = {
  year: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_YEAR': {
      const { year } = action.payload;
      return {
        ...state,
        year
      };
    }

    default: {
      return { ...state };
    }
  }
};

const YearContext = createContext({
  ...initialAuthState,
  setYear: () => Promise.resolve(),
  getYear: () => Promise.resolve()
});

export const YearProvider = ({ children }) => {
  // const { setYear } = useStream();
  const isMountedRef = useIsMountedRef();

  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const setYear = async (app, id, year) => {
    dispatch({
      type: 'SET_YEAR',
      payload: {
        year: null
      }
    })
    try {
      const ADD = gql`
        mutation MyMutation(
          $app_name: String
          $session_key: String
          $session_value: String
        ) {
          add_session_value(
            app_name: $app_name
            session_key: $session_key
            session_value: $session_value
          ) {
            _id
          }
        }
      `;

      const { data } = await client
        .mutate({
          mutation: ADD,
          variables: {
            app_name: app,
            session_key: id,
            session_value: year
          }
        })
        .then(res => {
          return res;
        });
    } catch (err) {
      // console.log(err);
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      // console.log(err.message);
    }
  };
  const getYear = useCallback(async () => {
    try {
      const getstudents = gql`
        query MyQuery {
          user_by_email_tenant {
            _id
            session_details {
              app_name
              session_key
              session_value
            }
          }
        }
      `;

      const { data } = await client
        .query({
          query: getstudents,
          fetchPolicy: 'network-only'
        })
        .then(res => {
          // console.log(res);
          return res;
        });

      if (isMountedRef.current) {
        const value = data.user_by_email_tenant.session_details.find(
          x => x.app_name == 'payroll' && x.session_key == 'Financial Year'
        );
        // console.log('user_by_email_tenant', value);
        if(!value){

          try {
            const getstudents = gql`
              query MyQuery {
                get_financial_years {
                  _id
                  year_name
                }
              }
            `;
      
            const { data } = await Settingclient
              .query({
                query: getstudents,
      
                fetchPolicy: 'network-only'
              })
              .then(res => {
                // console.log(res);      
                return res;
              });
      
            if (isMountedRef.current) {
              await setYear('payroll', 'Financial Year', data.get_financial_years[0].year_name);
      window.location.reload();
             
            }
          } catch (err) {
            // console.error(err);
          }

        }else{
        dispatch({
          type: 'SET_YEAR',
          payload: {
            year: value.session_value
          }
        });}
        
      }
    } catch (err) {
      // console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getYear();
  }, [getYear]);

  return (
    <YearContext.Provider
      value={{
        ...state,
        setYear,
        getYear
      }}
    >
      {children}
    </YearContext.Provider>
  );
};

export default YearContext;
