import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { onError } from 'apollo-link-error';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { ApolloLink, Observable } from 'apollo-link';
import awsconfig from 'src/utils/aws-exports-admission';
import awsconfigProd from 'src/utils/aws-exports-prod-admission';
import Cookies from 'js-cookie';
import axios from 'axios';

if (process.env.REACT_APP_ENV === 'test') {
  var url = awsconfig.aws_appsync_graphqlEndpoint;
  var region = awsconfig.aws_appsync_region;

  var auth = {
    type: awsconfig.aws_appsync_authenticationType,
    jwtToken: async () => `${Cookies.get('idToken')}`
  };

  var refreshTokenUrl =
    'https://jwa0stlj67.execute-api.ap-south-1.amazonaws.com/test/tokenRefresh';
} else {
  var url = awsconfigProd.aws_appsync_graphqlEndpoint;
  var region = awsconfigProd.aws_appsync_region;

  var auth = {
    type: awsconfigProd.aws_appsync_authenticationType,
    jwtToken: async () => `${Cookies.get('idToken')}`
  };

  var refreshTokenUrl =
    'https://jwa0stlj67.execute-api.ap-south-1.amazonaws.com/prod/tokenRefresh';
}

const httpLink = new HttpLink({ uri: url });

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
   
    if (graphQLErrors && graphQLErrors[0]?.message === 'Token has expired.') {
      // Token has expired, refresh it
      return new Observable(async observer => {
        try {
          const rfsrt = Cookies.get('rfsrt'); // Replace with your actual refresh token retrieval logic
    
          const options = {
            headers: { rfsrt },
            withCredentials: true,
            skipAuthRefresh: true
          };
          const payload = {
            rfsrt: rfsrt,
            app_name: 'human resource management service'
          };

          // Make a token refresh request using Axios
          const tokenRefreshResponse = await axios.post(
            refreshTokenUrl,
            payload,
            { options }
          );
          var accessToken = tokenRefreshResponse.data.data.AccessToken;
          var idToken = tokenRefreshResponse.data.data.IdToken;
          Cookies.set('accessToken', accessToken);
          Cookies.set('idToken', idToken);

          operation.setContext({
            headers: {
              authorization: idToken
            }
          });

          // Retry the original operation
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          };

          forward(operation).subscribe(subscriber);
        } catch (error) {
          observer.error(error);
        }
      });
    }
  }
);

const link = ApolloLink.from([
  // errorLink,
  createAuthLink({ url, region, auth }),
  errorLink,
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink)
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only'
    },
    query: {
      fetchPolicy: 'network-only'
    },
    
  }
});

export default client;
