/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import Logo from 'src/components/Logo';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { Link as RouterLink } from 'react-router-dom';
import Contacts from './Contacts';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Chip,
  Hidden,
  AppBar,
  Toolbar,
  IconButton,
  Link,
  List,
  SvgIcon,
  ListSubheader,
  Typography,
  makeStyles,
  withStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import Account from './Account';
import Stream from './Stream';
import Year from './Year';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import TransformOutlinedIcon from '@material-ui/icons/TransformOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import SplashScreen from 'src/components/SplashScreen';
import Search from './Search';
import Settings from './Settings';
import {
  Edit as EditIcon,
  BarChart as BarChartIcon,
  PieChart as PieChartIcon
} from 'react-feather';
import NavItem from './NavItem';
import Label from 'src/components/Label';
import Notifications from './Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import SwapVerticalCircleOutlinedIcon from '@material-ui/icons/SwapVerticalCircleOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import GetAppIcon from '@material-ui/icons/GetApp';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import PhoneIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@material-ui/icons/Business';
import PaymentsIcon from '@mui/icons-material/Payments';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DateRangeIcon from '@material-ui/icons/DateRangeOutlined';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import CallToActionOutlinedIcon from '@material-ui/icons/CallToActionOutlined';
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import CastForEducationOutlined from '@material-ui/icons/CastForEducationOutlined';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import ViewStreamOutlinedIcon from '@material-ui/icons/ViewStreamOutlined';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import SecurityIcon from '@material-ui/icons/Security';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { ChevronLeft } from 'react-feather';
import GroupIcon from '@material-ui/icons/Group';
import DetailsIcon from '@material-ui/icons/Details';
import BlueButton from 'src/components/Buttons/RoundOutlineBlue';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSnackbar } from 'notistack';
import PersonIcon from '@mui/icons-material/Person';
const ColorAppBar = withStyles({
  root: {
    backgroundColor: '#4F4E7D'
  }
})(AppBar);

const Dashboard = {
  items: [
    {
      title: 'Dashboard',
      href: '/app/payroll/home',
      icon: DashboardOutlinedIcon
    }
  ]
};

const Owner = {
  items: [
    {
      title: 'Employees',
      href: '/app/admin/Faculty',
      icon: Groups2OutlinedIcon
    },
    {
      title: 'Payout',
      href: '/app/payroll/schedule',
      icon: LocalAtmOutlinedIcon
    },
    {
      title: 'Employee Tracker',
      href: '/app/admin/Employees',
      icon: BadgeOutlinedIcon,
      items: [
        {
          title: 'Attendance',
          href: '/app/admin/Employees/attendance'
          // icon: AssignmentTurnedInOutlinedIcon
        },
        {
          title: 'Leaves Applications',
          href: '/app/admin/Employees/leaves'
          // icon: AssignmentTurnedInOutlinedIcon
        },
        {
          title: 'Movement Register',
          href: '/app/admin/Employees/movement'
          // icon: AssignmentTurnedInOutlinedIcon
        }
      ]
    },
    {
      title: 'HR Tracker',
      href: '/app/payroll',
      icon: BadgeOutlinedIcon,
      items: [
        {
          title: 'Attendance',
          href: '/app/payroll/attendance'
          // icon: AssignmentTurnedInOutlinedIcon
        },

        {
          title: 'Leave Applications',
          href: '/app/payroll/leaveApplications'
          // icon: TransformOutlinedIcon
        },
        {
          title: 'Movement Register',
          href: '/app/payroll/movementRegister'
          // icon: TransferWithinAStationIcon
        }
      ]
    },
    {
      title: 'Approvals',
      href: '/app/payroll/approvals',
      icon: TransformOutlinedIcon
    },
    {
      title: 'Reports',
      href: '/app/payroll/reports',
      icon: AssessmentOutlinedIcon
    },
    {
      title: 'User Management',
      href: '/app/admin/user_management',
      icon: SwapVerticalCircleOutlinedIcon
    },
    {
      title: 'Settings',
      href: '/app/payroll/setup',
      icon: SettingsOutlinedIcon,
      items: [
        {
          title: 'Face Id Setup',
          href: '/app/payroll/setup/attendance'
        },
        {
          title: 'Dept Admin Setup',
          href: '/app/payroll/setup/deptAdminSetup'
        }
      ]
    }
  ]
};

const HOD = {
  items: [
    {
      title: 'Employees',
      href: '/app/admin/Faculty',
      icon: Groups2OutlinedIcon
    },

    {
      title: 'Employee Tracker',
      href: '/app/admin/Employees',
      icon: BadgeOutlinedIcon,
      items: [
        {
          title: 'Attendance',
          href: '/app/admin/Employees/attendance'
          //   // icon: AssignmentTurnedInOutlinedIcon
        },

        {
          title: 'Leaves',
          href: '/app/admin/Employees/leaves'
          // icon: AssignmentTurnedInOutlinedIcon
        },
        {
          title: 'Movement Register',
          href: '/app/admin/Employees/movement'
          // icon: AssignmentTurnedInOutlinedIcon
        }
      ]
    },
    {
      title: 'HR Tracker',
      href: '/app/payroll',
      icon: BadgeOutlinedIcon,
      items: [
        {
          title: 'Attendance',
          href: '/app/payroll/attendance'
          // icon: AssignmentTurnedInOutlinedIcon
        },
        {
          title: 'Movement Register',
          href: '/app/payroll/movementRegister'
          // icon: TransferWithinAStationIcon
        },
        {
          title: 'Leave Applications',
          href: '/app/payroll/leaveApplications'
          // icon: TransformOutlinedIcon
        }
      ]
    },
    {
      title: 'Approvals',
      href: '/app/payroll/approvals',
      icon: TransformOutlinedIcon
    },
    {
      title: 'Reports',
      href: '/app/payroll/reports',
      icon: AssessmentOutlinedIcon
    },

    {
      title: 'Settings',
      href: '/app/payroll/inchargeHOD',
      icon: SettingsOutlinedIcon,
      items: [
        {
          title: 'Incharge HOD',
          href: '/app/payroll/inchargeHOD'
        }
      ]
    }
  ]
};

const Users = {
  // subheader: 'Admission Settings Panel',
  items: [
    // {
    //   title: 'Profile',
    //   href: '/app/admin/profile1',
    //   icon: PersonOutlineIcon
    // },
    {
      title: 'Profile',
      href: '/app/admin/profile1/personaldetails',
      icon: PersonIcon
    },

    {
      title: 'Security',
      href: '/app/admin/profile1/security',
      icon: SecurityIcon
    },
    {
      title: 'ID card',
      href: '/app/admin/profile1/Access',
      icon: AccountBoxIcon
    },
    {
      title: 'Wallet',
      href: '/app/admin/profile1/Access1',
      icon: AccountBalanceWalletIcon
    },
    {
      title: 'Company',
      href: '/app/admin/profile1/Access2',
      icon: BusinessIcon,
      items: [
        {
          title: 'Polices',
          href: '/app/admin/profile1/Access2',
          icon: ViewStreamOutlinedIcon
        },
        {
          title: 'Notification',
          href: '/app/admin/profile1/Access2',
          icon: GroupWorkOutlinedIcon
        },

        {
          title: 'About Us',
          href: '/app/admin/profile1/Access2',
          icon: ClassOutlinedIcon
        },
        {
          title: 'FAQ',
          href: '/app/admin/profile1/Access2',
          icon: LiveHelpIcon
        },
        {
          title: 'Contact Us',
          href: '/app/admin/profile1/Access2',
          icon: PhoneIcon
        },
        {
          title: 'Compliance',
          href: '/app/admin/profile1/Access2',
          icon: CastForEducationOutlined
        }
      ]
    },
    {
      title: 'Account Activity',
      href: '/app/admin/profile1/Access3',
      icon: LocalActivityIcon,
      items: [
        {
          title: 'Login History',
          href: '/app/admin/profile1/Access3',
          icon: ViewStreamOutlinedIcon
        },
        {
          title: 'Session History',
          href: '/app/admin/profile1/Access3',
          icon: GroupWorkOutlinedIcon
        },
        {
          title: 'Recent Activity',
          href: '/app/admin/profile1/Access3',
          icon: DateRangeIcon
        }
      ]
    },
    {
      title: 'Groups',
      href: '/app/admin/profile1/Access4',
      icon: GroupIcon
    },
    {
      title: 'Organization',
      href: '/app/admin/profile1/Access5',
      icon: SupervisedUserCircleIcon
    },
    // {
    //   title: 'Logout',
    //   href: '/login',
    //   icon: ExitToAppIcon
    // }
  ]
};

const Attendance = {
  items: [
    {
      title: 'Attendance',
      href: '/app/payroll/attendance',
      icon: AssignmentTurnedInOutlinedIcon
    },
    ,
    {
      title: 'Settings',
      href: '/app/payroll/inchargeHOD',
      icon: SettingsOutlinedIcon,
      items: [
        {
          title: 'Attendance Setup',
          href: '/app/payroll/setup/attendance'
        }
      ]
    }
  ]
};

const Attendance_entry = {
  items: [
    {
      title: 'Attendance Entry',
      href: '/app/payroll/attendanceEntry',
      icon: AssignmentTurnedInOutlinedIcon
    }
  ]
};

const load = state => {
  if (state) return <SplashScreen />;
};
function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}
// const handleLogout = async () => {
//     try {
//       handleClose();
//       await logout();
//       history.push('/login');
//     } catch (err) {
//       enqueueSnackbar('Unable to logout', {
//         variant: 'error'
//       });
//     }
//   };
function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(<></>);
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const sections = [
  {
    items: [
      {
        icon: PieChartIcon,
        href: <Search />
      },
      {
        icon: BarChartIcon,
        href: <Search />
      }
    ]
  }
];

function renderRightNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceRightChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceRightChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderRightNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useRightStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 70,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const useLogoStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 70,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 70
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const TopBar = ({
  className,
  open,
  setOpen,
  onMobileNavOpen,
  onMobileNavClose,
  ...rest
}) => {
  const classes = useStyles();
  const rightClasses = useRightStyles();
  const logoClasses = useLogoStyles();
  const location = useLocation();
  const { user } = useAuth();
  console.log(open);
  let users = '';

  const [image, setImage] = useState(null);
  const arrayBufferToBase64 = buffer => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(b => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };

  let folderName = user['custom:institution_id'];
  var bucketName = 'erpinstitution';
  let img;
  let logo;
  const s3 = new AWS.S3();

  async function get_logo(image_path) {
    let img = await s3
      .getObject({
        Bucket: bucketName,
        Key: image_path
      })
      .promise()
      .then(res => {
        if (res) {
          console.log('logo', res);
          return res;
        } else {
          return null;
        }
      })
      .catch(err => {
        console.log(err);
      });
    return img;
  }

  const getCustomers = useCallback(async () => {
    try {
      const get_image = gql`
        query get_setup {
          get_setup {
            _id
            institution_logo
          }
        }
      `;
      var image_path =
        encodeURIComponent(folderName) +
        '/' +
        encodeURIComponent('logo') +
        '/' +
        user['custom:institution_id'];

      img = await get_logo(image_path);
      if (img === undefined || img === null) {
        logo = null;
      } else {
        var base64Flag = `data:${img.contentType};base64,`;
        var imgData = arrayBufferToBase64(img.Body);
        logo = base64Flag + imgData;
        setImage(logo);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  const handleDrawer = () => {
    if (open) {
      setOpen(false);
      window.localStorage.setItem('drawer', false);
    } else {
      setOpen(true);
      window.localStorage.setItem('drawer', true);
    }
  };

  window.addEventListener('storage', function(e) {
    console.log('storage fired');
    // this.fetchData();
  });
  console.log(window.dispatchEvent(new Event('storage')));
  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      history.push('/login');
    } catch (err) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };
  window.addEventListener('storage', console.log);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  window.localStorage.setItem('test', '123');
  window.dispatchEvent(new Event('storage'));
  const theme = useTheme();
  const textSecondaryColor = theme.palette.text.secondary;
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box mb={1} mt={1} ml={1.5} display="flex">
            <PaymentsOutlinedIcon sx={{ color: '#4F4E7D' }} />
            <Box mr={1} />
            <Box mt={0.3}>
              <Typography variant="h5" color="textPrimary">
                Rove Payroll
              </Typography>
            </Box>
          </Box>
          <Box px={2} pt={2} mb={2}>
            <BlueButton
              variant="outlined"
              color="secondary"
              size="medium"
              fullWidth
              component={RouterLink}
              to={{
                pathname: `/app/payroll/home`
              }}
              startIcon={
                <SvgIcon fontSize="small">
                  <ChevronLeft strokeWidth="2px" size="24px" />
                </SvgIcon>
              }
            >
              Back to Main Menu
            </BlueButton>
          </Box>

          <List
            key={Users.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {Users.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: Users.items,
              pathname: location.pathname
            })}
          </List>
          <Box mt={-1} />
          <List
           onClick={handleLogout}
            subheader={
              <ListSubheader disableGutters disableSticky>
                <Box
                  px={1}
                  mb={2}
                  sx={{cursor:'pointer'}}
                  display={'flex'}
                  justifyContent={'start'}
                  alignContent={'center'}
                  alignItems={'center'}
                >
                  <Box>
                    <ExitToAppIcon />
                  </Box>
                  <Box mr={1}/>
                  <Box mb={1.5}>Logout</Box>
                </Box>
              </ListSubheader>
            }
          ></List>
        </Box>

        <Divider />
      </PerfectScrollbar>

      <Box flexGrow={1} />

      <Divider />
    </Box>
  );

  const Logocontent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Divider />

        <Box p={2}></Box>
        <Divider />
        <Box p={2} flexDirection="column" justifyContent="flex-end">
          <Box flexGrow={1} />
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              // component={RouterLink}
              // to="/docs"
              className={classes.action}
            >
              Check our docs
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
  const theme1 = useTheme();

  const mobileDevice = useMediaQuery(theme1.breakpoints.down('xs'));

  return (
    <div>
      {mobileDevice ? (
        <>
          {/* <CssBaseline />

          <ColorAppBar className={om ? classes.root1 : classes.root} {...rest}>
            <Toolbar className={classes.toolbar}>            
              <Stream />
              <Box ml={2} flexGrow={1} />
              <Year />
              <Term />
              <Box ml={2}>
                <Account Image={image} />
              </Box>
            </Toolbar>
          </ColorAppBar>
          <Drawer
            classes={{ paper: classes.mobileDrawer }}
            variant="temporary"
            anchor="left"
            open={om}
            onClose={handleDrawer1}
          >
            {content}
          </Drawer> */}
        </>
      ) : (
        <>
          {' '}
          <CssBaseline />
          <ColorAppBar className={clsx(classes.root, className)} {...rest}>
            <Toolbar className={classes.toolbar}>
              {open === true && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawer}
                  edge="start"
                  className={clsx(classes.menuButton, open)}
                >
                  <MenuIcon fontSize="large" />
                </IconButton>
              )}
              {open === false && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawer}
                  edge="start"
                  className={clsx(classes.menuButton, open)}
                >
                  <MenuOpenIcon fontSize="large" />
                </IconButton>
              )}

              <Hidden mdDown>
                {/* <Avatar
          src= {"/static/company.png"}
          ></Avatar> */}
                <Box />
                {/* <Avatar
          src= {image}
          ></Avatar> */}
              </Hidden>
              <Stream image={image} />

              <Box ml={2} flexGrow={1} />
              <Year />
              {/* <Search /> */}
              {/* <Contacts /> */}
              <Box ml={1}>
                <Notifications />
              </Box>
              {/*  <Settings /> */}
              <Box ml={2}>
                <Account />
              </Box>
            </Toolbar>
          </ColorAppBar>
          <Drawer
            classes={{ paper: classes.desktopDrawer }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            {content}
          </Drawer>
        </>
      )}
      {/* {open === false && (
        <Hidden mdDown>
       <Drawer
       classes={{ paper: logoClasses.desktopDrawer }}
       variant="persistent"
       anchor="left"
       open={true}
     >
       {Logocontent}
     </Drawer>
     </Hidden>
     )} */}
      {/* <Drawer
        classes={{ paper: rightClasses.desktopDrawer }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        {Rightcontent}
      </Drawer> */}
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
