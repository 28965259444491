import getInitials from 'src/utils/getInitials';
import getName from 'src/utils/getName';
import ChangePassword from 'src/views/auth/LoginView/Change_Password';

import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  Card,
  ButtonBase,
  Hidden,
  Menu,
  IconButton,
  Grid,
  CardHeader,
  MenuItem,
  SvgIcon,
  Dialog,
  Typography,
  makeStyles
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useAuth from 'src/hooks/useAuth';
import gql from 'graphql-tag';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { ChevronDown } from 'react-feather';
import client from 'src/utils/GQLSettingsClient';
import useYear from 'src/hooks/useYear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles(theme => ({
  avatar: {
    //   height: 35,
    //   width: 35,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  },
  item: {
    margin: 0,
    padding: 5,
    // paddingLeft: 4,
    // borderRadius: '12px',
    // '&:hover': {
      backgroundColor: '#fafafa'
    // }
    // alignItems: 'center'
  }
}));
const noPointer = { cursor: 'pointer' };

const Account = image => {
  const { setYear, year } = useYear();
  const isMountedRef = useIsMountedRef();
  const [loading, setLoading] = useState(true);

  // setYear('nutan');
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  // console.log('yearselected', year);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [settings, setSettings] = useState([
    {  },
    { }
  ]);

  const [open, setOpen1] = useState(false);
  const handleClickOpen = async data => {
    try {
      handleClose()
      await setYear('payroll', 'Financial Year', data.year_name);
      window.location.reload();

    } catch {
      // console.log('err');
    } finally {
      // window.location.reload();
    }
  };
  const handleClickClose = () => {
    setOpen1(false);
  };

  const getYear = useCallback(async () => {
    try {
      const getstudents = gql`
        query MyQuery {
          get_financial_years {
            _id
            year_name
          }
        }
      `;

      const { data } = await client
        .query({
          query: getstudents,

          fetchPolicy: 'network-only'
        })
        .then(res => {
          // console.log(res);

          return res;
        });

      if (isMountedRef.current) {
        setSettings(data.get_financial_years);
        // console.log(data.get_financial_years);
        
        setLoading(false);
       
      }
    } catch (err) {
      // console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getYear();
  }, [getYear]);

  // console.log('user', user);

  return (
    <>
      <CardHeader
        className={classes.item}
        style={noPointer}
        title={
          <Box
            display="flex"
            // alignItems="left"
            // component={ButtonBase}
            onClick={handleOpen}
            ref={ref}
            // className={classes.item}
          >
            <Box>
              <Box display="flex">
                <Hidden smDown>
                <Box mr={1} />
                {year?<>  <Typography variant="h6" textAlign="left" color="textPrimary">
                    Financial Year
                  </Typography>
                  <Box mr={1} /></>:<></>}
                </Hidden>
                {year?<Typography variant="h6" textAlign="left" color="textPrimary">
                  {year ? year : ''}
                </Typography>:<Skeleton width={200} />}
                <Hidden smDown>
                  <Box mr={1} />
                  {year?<SvgIcon cursor="pointer">
                    <ChevronDown strokeWidth="2px" size="20px" color="textPrimary" />
                  </SvgIcon>:<></>}
                </Hidden>
              </Box>
            </Box>
            {/* <Box display="flex">
                <Box>
                  <Typography variant="h6" textAlign="left" color="white">
                    {stream.stream_code ? stream.stream_code : ''}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    textAlign="left"
                    color="white"
                  >
                    {stream.institution ? stream.institution.institution_name : ''}
                  </Typography>
                </Box>
                <Box mr={1} mt={1}>
                  <SvgIcon cursor="pointer">
                    <ChevronDown strokeWidth="2px" size="20px" color="white" />
                  </SvgIcon>
                </Box>
              </Box> */}
          </Box>
        }
      ></CardHeader>

      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {settings.map(data => (
          <MenuItem
            onClick={e => handleClickOpen(data)}
            // style={{ padding: '21px' }}
          >
            {data.year_name ? data.year_name : ''}
          </MenuItem>
        ))}
      </Menu>
      <Dialog maxWidth="sm" fullWidth onClose={handleClickClose} open={open}>
        <ChangePassword open={handleClickClose} />
      </Dialog>
    </>
  );
};

export default Account;
