import React from 'react';
import {
  Box,
  makeStyles,
  List,
  ListSubheader,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import LabelItem from './NavItem';
import PropTypes from 'prop-types';
import { useLocation, matchPath } from 'react-router-dom';
import {
  useDashboardContext
} from 'src/contexts/DashboardContext';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    },
  wrapper: {
    display: 'flex',
    flexGrow: 1, 
    paddingLeft : 256,
    scrollBehavior: 'smooth',

  },
  sidebar: {
    minWidth: 256,
    //borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#e0e0e0',
    position: 'fixed', 
    top: 64, 
    bottom: 0, 
    left: 256, 
    zIndex: 1000, 
    overflowY: 'auto', 
    height: 'calc(100% - 64px)'
   
  },

  sidebar1: {
    minWidth: 256,
    //borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#e0e0e0',
    position: 'fixed', 
    top: 64,
    bottom: 0, 
    left: 0, 
    zIndex: 1000, 
    overflowY: 'auto', 
    height: 'calc(100% - 64px)'
  },
  content: {
    flexGrow: 1,
    scrollBehavior: 'smooth',
  },
}));

const Applications = ({ children }) => {
  const classes = useStyles();
  const { open, setOpen } = useDashboardContext();

  const Users = {
    items: [
      {
        title: 'General Settings',
        href: '/app/Setting/GeneralSettings/:id',
        //icon: 'fluent:settings-24-regular',
        items: [
          {
            title: 'Personal',
            href: '/app/Setting/GeneralSettings/Personal',
          },
          {
            title: 'Institution',
            href: '/app/Setting/GeneralSettings/Company',
          },
          // {
          //   title: 'Notification Settings',
          //   href: '/app/Setting/GeneralSettings/NotificationSettings',
          // }
        ]
      },
      {
        title: 'Organization Settings',
        href: '/app/admin/settings',
        //icon: 'fluent:settings-20-regular',
        items: [
          // {
          //   title: 'Programs',
          //   href: '/app/admin/settings/programs',
          // },
          // {
          //   title: 'Quota',
          //   href: '/app/admin/settings/quota',
          // },
          {
            title: 'Stream',
            href: '/app/admin/settings/stream',
          },
          {
            title: 'Batch',
            href: '/app/admin/settings/batch',
          },
          {
            title: 'Academic Year',
            href: '/app/admin/settings/academicyear',
          },
        ]
      },
      {
        title: 'User Management',
        href: '/app/setting/usermanagement',
        //icon: 'iconoir:profile-circle',
        items: [
          // {
          //   title: 'User Groups',
          //   href : '/app/setting/usermanagement/applicationsNew',
          //   //icon: 'tdesign:menu-application'
          // },
          {
            title: 'Users',
            href: '/app/setting/usermanagement/user_management',
            //icon: 'ri:swap-line'
          },
          {
            title: 'Data Access',
            href: '/app/setting/usermanagement/enabledsoon/data_access',
            //icon: 'carbon:event-schedule'
          },
          {
            title: 'Roles & Permissions',
            href: '/app/setting/usermanagement/enabledsoon/roles_&_permissions',
            //icon: 'carbon:event-schedule'
          },
          
          // {
          //   title: 'Users',
          //   href: '/app/Setting/Usermanagement/Users/UsersDetails',
          // },
          // {
          //   title: 'Access Control',
          //   href: '/app/Setting/Usermanagement/AccessControll/Details',
          //   info:'iconoir:lock'
          // }
        ]
      },
      {
        title: 'Integration',
        href: '/app/Setting/Integration/IntegrationDetails',
       // icon: 'bx:buildings',
       
      },
      {
        title: 'Payroll Settings',
        href: '/app/payroll',
        //icon: 'iconoir:send-diagonal',
        items: [
          {
            title: 'Dept Admin Setup',
            href: '/app/payroll/setup/deptAdminSetup',
          },
          {
            title: 'Manage Attendance',
            href: '/app/payroll/ManageAttendance',
          },
          // {
          //   title: 'Whatsapp',
          //   href: '/app/admin/settings/templates/WhatsappTemplete',
          // },
         
        ]
      },
      {
        title: 'Template Settings',
        href: '/app/admin/templates/settings',
        //icon: 'iconoir:send-diagonal',
        items: [
          {
            title: 'Email',
            href: '/app/admin/templates/settings/EmailTemplate',
          },
        
          // {
          //   title: 'Whatsapp',
          //   href: '/app/admin/settings/templates/WhatsappTemplete',
          // },
         
        ]
      },

    ]
  };

  function renderNavItems({ items, pathname, depth = 0 }) {
    return (
      <List disablePadding>
        {items.reduce(
          (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
          []
        )}
      </List>
    );
  }

  function reduceChildRoutes({ acc, pathname, item, depth }) {
    const key = item.title + depth;

    if (item.items) {
      const open = matchPath(pathname, {
        path: item.href,
        exact: false
      });

      acc.push(
        <LabelItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
            
          })}
        </LabelItem>
      );
    } else {
      acc.push(
        <LabelItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
        />
      );
    }

    return acc;
  }

  const location = useLocation();

  const content = (
         <Box  px={2} pb ={2}>
          <List
            key={Users.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {Users.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: Users.items,
              pathname: location.pathname
            })}
          </List>
        </Box>

  );
  return (
    <div className={classes.root}>
    <Box className={open ?  classes.sidebar : classes.sidebar1}>
      {/* <PerfectScrollbar options={{ suppressScrollX: true }}> */}
        <List>{content}</List>
      {/* </PerfectScrollbar> */}
    </Box>
    <Box className={classes.wrapper}>
      <Box className={classes.content} >
      {/* <PerfectScrollbar options={{ suppressScrollX: true }}> */}
        {children}
        {/* </PerfectScrollbar> */}
        </Box>
    </Box>
  </div>
  );
};

Applications.propTypes = {
  children: PropTypes.node
};
export default Applications;
